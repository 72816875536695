import { useMemo, useState } from 'react'
import { useFormik, FormikConfig, FormikProps } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

interface SubmitCb {
    onSuccess?: (res: any) => void;
    onError?: (error: any) => void;
    onSetError: (error: any) => void;
  }

const defaultSetError = (err: any) => {
  return err.response.data ? err.response.data.error : err
}

export const useForm = (params) => {
    const {
        onSubmitSuccess,
        onSubmitError,
        onSetError = defaultSetError,
        ...formParams
    } = params
    const form: FormikProps<any> = useFormik<any>(formParams as FormikConfig<any>)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState(null)

    const validation = useMemo(() => {
        const {
            validationSchema,
            validateOnChange = false,
            validateOnBlur = false,
        } = formParams
        if (!validationSchema) return {}
        return ({
            validationSchema: toFormikValidationSchema(validationSchema),
            validateOnChange,
            validateOnBlur,
        })
    }, [formParams])

    const handleSubmit = async (values, formikBag) => {
        if (error) setError(null)
        setLoading(true)
        let res = null
        try {
            res = await params.onSubmit(
                    values,
                    { onSuccess: onSubmitSuccess, onError: onSubmitError },
                    formikBag,
                )
                .then((response) => {
                    if (onSubmitSuccess) onSubmitSuccess(response)
                    return response
                })
                .catch((err) => {
                    console.error(err)
                    setError(onSetError(err))
                    if (onSubmitError) onSubmitError(err)
                })
        } catch (err) {
            console.error(err)
            setError(onSetError(err))
            if (onSubmitError) onSubmitError(err)
        }
        setLoading(false)
        if (!error && onSubmitSuccess) onSubmitSuccess(res)
        return res
    }

    return {
        ...form,
        ...validation,
        error,
        isSubmitting: loading,
        onSubmit: handleSubmit,
    }
}

export default useForm
