import Message, { MessageTitle } from './Message'

export type { MessageProps } from './Message'

export {
    Message,
    MessageTitle,
}

export default Message
