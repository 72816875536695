import { useMediaQuery, useTheme } from '@mui/material'

export const useViewport = () => {
  const theme = useTheme()
  const isExtraSmall = useMediaQuery(theme.breakpoints.up('xs'))
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'))
  const isMedium = useMediaQuery(theme.breakpoints.up('md'))
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'))

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return {
    useMediaQuery,
    breakpoints: theme.breakpoints,
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
    isMobile,
    isDesktop,
  }
}

export default useViewport
