import React from 'react'
import MuiLink, { LinkProps } from '@mui/material/Link'

declare module '@mui/material/Link' {
  interface LinkPropsColorOverrides {
    plain: true
    branding: true
    campus: true
    kitchen: true
    kiosk: true
    luxo: true
  }
}

export const Link = <C extends React.ElementType='a'>(props: LinkProps<C, { component?: C }>) => <MuiLink {...props} />

export default Link
