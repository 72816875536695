export type AppName = 'campus' | 'kitchen' | 'kiosk' | 'luxo'

export const AppDomainRegex = /(tools|campus|schools|kitchen|chefs|kiosk|admin|luxo)\./

export const AppDomains = {
  campus: 'schools',
  kitchen: 'chefs',
  kiosk: 'kiosk',
  luxo: 'luxo',
}
export const AppBasePaths = {
  campus: 'schools',
  kitchen: 'chefs',
  kiosk: 'kiosk',
  luxo: 'luxo',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useBaseUrl = (appName: AppName): string => {
  const metaBasename: HTMLMetaElement = document.head.querySelector('meta[name="basename"]')
  const basename: string | undefined = metaBasename ? metaBasename.content : undefined

  if (basename === undefined) {
    return window.location.origin.replace(AppDomainRegex, `${AppDomains[appName]}.`)
  } else {
    return `${window.location.origin}/${AppBasePaths[appName]}`
  }
}

export default useBaseUrl