import { FormEvent, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import axios from 'axios'
import apolloClient from 'graphql/client'
import type { LocationWithFromPath } from 'types/routes'
import { useGlobalContext } from 'hooks'

export const useRegister = (state: Record<string, unknown>, setError?: (error: unknown) => void) => {
  const { token, setToken } = useGlobalContext()
  const apollo = apolloClient(token)
  const navigate = useNavigate()
  const location: LocationWithFromPath = useLocation()
  const from = (location.state?.from?.pathname ? `${location.state?.from?.pathname}${location.state?.from?.search ? location.state?.from?.search : ''}` : "/")
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    if (token) navigate(from, { replace: true })
  }, [token, from, navigate])

  // TODO create default axios client for re-use
  const submitRegister = (e: FormEvent): void => {
    e.preventDefault()
    setLoading(true)
    axios.post(
      '/users',
      { user: state },
      { headers: { 'Accept': 'application/json' } }
    ).then((response) => {
      setLoading(false)
      setToken(response.headers['authorization'], response.data.refresh)
      apollo.resetStore()
    }).catch((error) => {
      setLoading(false)
      if (!setError) return

      if (error.response.data) {
        setError(error.response.data.error)
      } else {
        setError(error)
      }
    })
  }

  return { token, submitRegister, loading }
}

export default useRegister
