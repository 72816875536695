import React from 'react'
import { TodoTask } from 'graphql/schema/graphql'
import { Box } from 'components'
import { TodoWrapperProps } from './TodoWrapper'
import { Stepper, MobileStepper } from './Stepper'
import { useTodosWizard, RefetchFn, TodosWizard } from 'hooks/useTodosWizard'
import { IconProps } from '@mui/material'
import { useViewport } from 'hooks'

export type TaskWithComponent = TodoTask & { component: React.FC<{ todo: TaskWithComponent }>, icon: React.FC<IconProps> }

export interface TodoProps {
  todos: TaskWithComponent[]
  todo: TaskWithComponent
  refetch: RefetchFn
}

export const DesktopTodo: React.FC<{ todo: TaskWithComponent, wizard: TodosWizard }> = ({ todo, wizard }) => {
  const TodoComponent: React.FC<TodoWrapperProps> = todo?.component

  return <Box display="flex" height="100%" alignItems="stretch">
    <Box pl={3} pr={4}>
      <Stepper wizard={wizard} />
    </Box>

    <Box pl={4} pr={3} flexGrow={1}>
      { todo && <TodoComponent todo={todo} wizard={wizard} /> }
    </Box>
  </Box>
}

export const MobileTodo: React.FC<{ todo: TaskWithComponent, wizard: TodosWizard }> = ({ todo, wizard }) => {
  const TodoComponent: React.FC<TodoWrapperProps> = todo?.component

  return <Box height="100%" position="relative">
    <MobileStepper wizard={wizard} position="top" />

    <Box pt={8} pb={4}>
      { todo && <TodoComponent todo={todo} wizard={wizard} /> }
    </Box>
  </Box>
}



export const Todo: React.FC<TodoProps> = ({ todos, todo, refetch }) => {
  const wizard = useTodosWizard({ todos, todo, refetch })
  const { isDesktop } = useViewport()

  return isDesktop ? <DesktopTodo todo={todo} wizard={wizard} /> : <MobileTodo todo={todo} wizard={wizard} />
}

export default Todo
