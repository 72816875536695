import React, { PropsWithChildren, createContext, useCallback, useReducer } from 'react'
import { useLocalStorage } from 'hooks'

export type GlobalContext = {
  token?: string
  refresh?: string
  setToken?: (token?: string, refresh?: string) => void
}

const initialState: GlobalContext = {
  token: null,
  refresh: null,
}

export const ApplicationContext = createContext<GlobalContext>(initialState)

export type ContextAction =
  | { type: 'SET_TOKEN', payload: { token: string, refresh: string } }

const contextReducer = (state: GlobalContext, action: ContextAction) => {
  switch(action.type) {
    case 'SET_TOKEN':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export const ContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [stored, setStored] = useLocalStorage<GlobalContext>('globalContext', initialState)

  const localStorageReducer = useCallback(
    (state: GlobalContext, action: ContextAction) => {
      const newState = contextReducer(state, action)
      setStored(newState)
      return newState
    },
    [setStored]
  )
  const [state, dispatch] = useReducer(localStorageReducer, stored)

  const setToken = useCallback(
    (token: string, refresh: string) => dispatch({ type: 'SET_TOKEN', payload: { token, refresh }}),
    [dispatch]
  )

  const contextValue: GlobalContext = {
    ...state,
    setToken,
  }

  return <ApplicationContext.Provider value={contextValue}>
    {children}
  </ApplicationContext.Provider>
}

export default ContextProvider