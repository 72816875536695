import React, { useMemo } from 'react'
import InputMask from 'react-input-mask'
import PlainTextField from './PlainTextField'

const TelephoneField = (props) => {
  const handleChange = (e) => {
    e.target.value = e.target.value.replace(/\D/g, '')
    if (props.onChange) props.onChange(e)
  }

  const overrides = useMemo(() => (
    {
      InputProps: {
        inputComponent: InputMask,
        inputProps: {
          mask: '+1 (999) 999-9999', // TODO use locale
        },
      },
    }
  ), [])

  return <PlainTextField {...props} {...overrides} type="tel" onChange={handleChange} />
}

TelephoneField.defaultProps = {
  variant: 'outlined',
  position: 'end',
  icon: null,
}

export default TelephoneField
