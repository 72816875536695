import { updateMeMutation } from 'graphql/mutations'
import { MutationTuple, useMutation } from './useMutation'
import type { UpdateMeInput, UpdateMePayload } from 'graphql/schema/graphql'
export type { UpdateMeInput }

export type UpdateMeMutationData = { updateMe: UpdateMePayload }

export const useUpdateMe: (variables?: UpdateMeInput) => MutationTuple<UpdateMeMutationData, UpdateMeInput> = (variables)  => {
  const [ updateMeFn, updateMeResult ] = useMutation<UpdateMeMutationData, UpdateMeInput>(
    updateMeMutation,
    {
      variables,
      notifyOnNetworkStatusChange: true
    }
  )

  return [ updateMeFn, updateMeResult ]
}

export default useUpdateMe
