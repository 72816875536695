import { useCallback, useMemo, useState } from 'react'
import { merge, isEqual } from 'lodash'
import { useDrawerSettings, useViewport } from 'hooks'
import { QuerySortInput } from 'graphql/schema/graphql'
// import { GridSortItem } from '@mui/x-data-grid'

// NOTE: WORK IN PROGRESS
// declare enum FilterLogicOperator {
//   And = "and",
//   Or = "or"
// }

// declare enum FilterMatchOperator {
//   Equal = "equal",
//   Greather = "greater",
//   GreatherOrEqual = "greater_or_equal",
//   Less = "less",
//   LessOrEqual = "less_or_equal",
//   Match = "match",
//   NotEqual = "not_equal",
// }

// export { FilterLogicOperator, FilterMatchOperator }

// export type FilterValue = string | number | (string | number)[]

// export interface FilterItem<V=FilterValue> {
//   field: string
//   value: V
//   operator?: FilterMatchOperator
//   logicOperator?: FilterLogicOperator
// }

// export interface FilterModel {
//   filters: FilterItem[]
//   logicOperator: FilterLogicOperator
// }

export interface FilterOption {
  key: string
  value: string
  count?: number
}

export type FilterMatch<T extends object, D extends object> = (data: D, state: T) => boolean
export type FilterProcess<T extends object, D extends object> = (data: D, state: T) => D
export type FilterOnChange<T extends object> = (value: string, filters: T, checked?: boolean) => Partial<T>

export interface Filter<T extends object, D extends object, K extends keyof T = keyof T> {
  name: K
  label?: string
  options?: FilterOption[]
  match?: FilterMatch<T, D>
  process?: FilterProcess<T, D>
  onChange?: FilterOnChange<T>
}

export type Filters<T extends object, D extends object, K extends keyof T = keyof T> = Filter<T, D, K>[]

export type SetFilters<T extends object> = (state: Partial<T>) => void
export type ApplyFilters = () => void
export type ClearFilters<K> = (options?: { preserve?: K[] }) => void
export type FilterData<D extends object> = (data: D[]) => D[]
export type SetFilterLabel<K> = (key: K, label: string) => void
export type SetFilterOptions<K> = (key: K, options: FilterOption[], label?: string) => void
export type ApplyPagination = (pagination: { page: number, pageSize: number }) => void
export type ApplySorting = (sorting: QuerySortInput[]) => void


export interface FilterPagination {
  page: number
  pageSize: number
  rowCount: number
}

export interface UseFiltersReturn<T extends object, D extends object, K extends keyof T = keyof T> {
  filters: T
  appliedFilters: T
  filterDefinitions: Filters<T, D, K>
  pagination: FilterPagination
  sorting: QuerySortInput[]
  applicable: boolean
  clearable: boolean
  applyPagination: ApplyPagination
  applySorting: ApplySorting
  setFilters: SetFilters<T>
  applyFilters: ApplyFilters
  setAndApplyFilters: SetFilters<T>
  clearFilters: ClearFilters<K>
  filterData: FilterData<D>
  setFilterLabel: SetFilterLabel<K>
  setFilterOptions: SetFilterOptions<K>
}

export type FilterProps<T extends object, D extends object, K extends keyof T = keyof T> = UseFiltersReturn<T, D, K>

export interface FilterState<T extends object, D extends object, K extends keyof T = keyof T> {
  filters: T
  appliedFilters: T
  filterDefinitions: Filters<T, D, K>
  pagination: { page: number, pageSize: number, rowCount: number }
  sorting: QuerySortInput[]
}

export const useFilters = <T extends object, D extends object, K extends keyof T = keyof T>(definitions: Filters<T, D, K>, initialState: T, preserveOnClear?: K[], filterMode?: "server"): UseFiltersReturn<T, D, K> => {
  definitions.forEach((filter, index, array) => {
    const withDefaults: Filter<T, D, K> = merge({
      match: () => true,
      process: (result: D) => result,
    }, filter)

    array[index] = withDefaults
  })

  const { isDesktop } = useViewport()
  const [{}, { setContentDrawer }] = useDrawerSettings()

  const [ { filters, appliedFilters, filterDefinitions, pagination, sorting }, setState ] = useState<FilterState<T, D, K>>({
    filters: initialState,
    appliedFilters: initialState,
    filterDefinitions: definitions,
    pagination: { page: 0, pageSize: 25, rowCount: 0 },
    sorting: [],
  })

  const applicable = useMemo(() => {
    const compFilters: Partial<T> = { ...filters }
    const compAppliedFilters: Partial<T> = { ...appliedFilters }

    preserveOnClear?.forEach((key) => {
      compFilters[key] = undefined
      compAppliedFilters[key] = undefined
    })

    return !isEqual(compFilters, compAppliedFilters)
  }, [filters, appliedFilters, preserveOnClear])

  const clearable = useMemo(() => {
    const compInitial: Partial<T> = { ...initialState }
    const compFilters: Partial<T> = { ...filters }

    preserveOnClear?.forEach((key) => {
      compInitial[key] = undefined
      compFilters[key] = undefined
    })

    return !isEqual(compInitial, compFilters)
  }, [initialState, filters, preserveOnClear])


  const setFilters = useCallback<SetFilters<T>>((state) => setState((s) => ({ ...s, filters: { ...s.filters, ...state } })), [setState])

  const applyFilters = useCallback<ApplyFilters>(() => {
    setState((state) => ({
      ...state,
      appliedFilters: state.filters,
      pagination: { ...state.pagination, page: 0, pageSize: 25, rowCount: 0 },
    }))
    if (!isDesktop) setContentDrawer(false)
  }, [setState, setContentDrawer, isDesktop])

  const setAndApplyFilters = useCallback<SetFilters<T>>((state) => {
    setState((s) => ({
      ...s,
      filters: { ...s.filters, ...state },
      appliedFilters: { ...s.appliedFilters, ...state },
      pagination: { ...s.pagination, page: 0, pageSize: 25, rowCount: 0 },
    }))
  }, [setState])

  const clearFilters = useCallback<ClearFilters<K>>(({ preserve } = {}) => {
    setState((state) => {
      const preservedState: Partial<T> = {}
      const preservable = (preserveOnClear || []).concat(preserve || [])

      if (preservable?.length > 0) {
        preservable.forEach((p) => preservedState[p] = state.filters[p] )
      }

      const mergedState: T = merge(initialState, preservedState)
      return { ...state, filters: mergedState, appliedFilters: mergedState }
    })

    if (!isDesktop) setContentDrawer(false)
  }, [initialState, preserveOnClear, setState, setContentDrawer, isDesktop])

  const filterData = useCallback<FilterData<D>>((data) => {
    const filteredData: D[] = []

    data?.forEach((result) => {
      let match = true

      filterDefinitions.forEach((filter) => {
        if (match === false) return

        if (!filter.match(result, appliedFilters)) {
          match = false
          return
        }

        result = filter.process(result, appliedFilters)
      })

      if (match) filteredData.push(result)
    })

    return filteredData
  }, [filterDefinitions, appliedFilters])

  const setFilterLabel = useCallback<SetFilterLabel<K>>((key, label) => setState((state) => {
    const definitions = [ ...state.filterDefinitions ]
    const index = definitions.findIndex((definition) => definition.name === key)
    const definition = definitions[index]

    definitions.splice(index, 1, { ...definition, label })

    return { ...state, filterDefinitions: definitions }
  }), [setState])

  const setFilterOptions = useCallback<SetFilterOptions<K>>((key, options, label) => setState((state) => {
    const definitions = [ ...state.filterDefinitions ]
    const index = definitions.findIndex((definition) => definition.name === key)
    const definition = definitions[index]

    definitions.splice(index, 1, { ...definition, options, label: (label || definition.label) })

    const filters = { ...state.filters }
    const filter = filters[key]

    if (Array.isArray(filter) && filterMode != "server") { // only clear invalid options if not using filterMode "server"
      const optionValues = options.map(({ value }) => value)
      filters[key] = (filter as string[]).filter((value) => optionValues.includes(value)) as unknown as T[K]
    }

    return { ...state, filterDefinitions: definitions, filters: filters, appliedFilters: filters }
  }), [setState, filterMode])

  const applyPagination = useCallback((pageModel: { page: number, pageSize: number }) => {
    setState((state) => ({ ...state, pagination: { ...state.pagination, ...pageModel } }))
  }, [setState])

  const applySorting = useCallback((sortModel: QuerySortInput[]) => {
    setState((state) => ({ ...state, sorting: sortModel }))
  }, [setState])

  return {
    filters,
    appliedFilters,
    filterDefinitions,
    applicable,
    clearable,
    pagination,
    sorting,
    applyPagination,
    applySorting,
    setFilters,
    applyFilters,
    setAndApplyFilters,
    clearFilters,
    filterData,
    setFilterLabel,
    setFilterOptions,
  }
}

export default useFilters
