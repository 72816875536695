import React, { useCallback } from 'react'
import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/x-date-pickers-pro'
import { DesktopDatePicker as MuiDesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers-pro'
import { MobileDatePicker as MuiMobileDatePicker, MobileDatePickerProps } from '@mui/x-date-pickers-pro'
import { StaticDatePicker as MuiStaticDatePicker, StaticDatePickerProps } from '@mui/x-date-pickers-pro'
import { DateTimePicker as MuiDateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers-pro'
import { TimePicker as MuiTimePicker, TimePickerProps } from '@mui/x-date-pickers-pro'
import { DateRangePicker as MuiDateRangePicker, DateRangePickerProps } from '@mui/x-date-pickers-pro'
import { DesktopDateRangePicker as MuiDesktopDateRangePicker, DesktopDateRangePickerProps } from '@mui/x-date-pickers-pro'
import { MobileDateRangePicker as MuiMobileDateRangePicker, MobileDateRangePickerProps } from '@mui/x-date-pickers-pro'
import { StaticDateRangePicker as MuiStaticDateRangePicker, StaticDateRangePickerProps } from '@mui/x-date-pickers-pro'


export type {
  DatePickerProps,
  DesktopDatePickerProps,
  MobileDatePickerProps,
  StaticDatePickerProps,
  DateTimePickerProps,
  TimePickerProps,
  DateRangePickerProps,
  DesktopDateRangePickerProps,
  MobileDateRangePickerProps,
  StaticDateRangePickerProps,
}

export const DatePicker: <TDate>(props: DatePickerProps<TDate>) => React.ReactElement = (props) => <MuiDatePicker {...props} />
export const DesktopDatePicker: <TDate>(props: DesktopDatePickerProps<TDate>) => React.ReactElement = (props) => <MuiDesktopDatePicker {...props} />
export const MobileDatePicker: <TDate>(props: MobileDatePickerProps<TDate>) => React.ReactElement = (props) => <MuiMobileDatePicker {...props} />
export const StaticDatePicker: <TDate>(props: StaticDatePickerProps<TDate>) => React.ReactElement = (props) => <MuiStaticDatePicker {...props} />
export const DateTimePicker: <TDate>(props: DateTimePickerProps<TDate>) => React.ReactElement = (props) => <MuiDateTimePicker {...props} />
export const TimePicker: <TDate>(props: TimePickerProps<TDate>) => React.ReactElement = (props) => <MuiTimePicker {...props} />

const DummySeparator: React.FC = () => <></>

export const DateRangePicker: <TDate>(props: DateRangePickerProps<TDate>) => React.ReactElement = ({ onChange, slotProps={ field: { slots: { separator: DummySeparator } } }, ...props }) => {
  const onChangeCallback = useCallback((newDates, context) => {
    if (newDates.length < 2 || newDates[1] === null) return

    if (onChange) onChange(newDates, context)
  }, [onChange])

  return <MuiDateRangePicker {...props} onChange={onChangeCallback} slotProps={slotProps} />
}

export const DesktopDateRangePicker: <TDate>(props: DesktopDateRangePickerProps<TDate>) => React.ReactElement = ({ onChange, slotProps={ field: { slots: { separator: DummySeparator } } }, ...props }) => {
  const onChangeCallback = useCallback((newDates, context) => {
    if (newDates.length < 2 || newDates[1] === null) return

    if (onChange) onChange(newDates, context)
  }, [onChange])

  return <MuiDesktopDateRangePicker {...props} onChange={onChangeCallback} slotProps={slotProps} />
}

export const MobileDateRangePicker: <TDate>(props: MobileDateRangePickerProps<TDate>) => React.ReactElement = ({ onChange, slotProps={ field: { slots: { separator: DummySeparator } } }, ...props }) => {
  const onChangeCallback = useCallback((newDates, context) => {
    if (newDates.length < 2 || newDates[1] === null) return

    if (onChange) onChange(newDates, context)
  }, [onChange])

  return <MuiMobileDateRangePicker {...props} onChange={onChangeCallback} slotProps={slotProps} />
}

export const StaticDateRangePicker: <TDate>(props: StaticDateRangePickerProps<TDate>) => React.ReactElement = ({ onChange, slotProps, ...props }) => {
  const onChangeCallback = useCallback((newDates, context) => {
    if (newDates.length < 2 || newDates[1] === null) return

    if (onChange) onChange(newDates, context)
  }, [onChange])

  return <MuiStaticDateRangePicker {...props} onChange={onChangeCallback} slotProps={slotProps} />
}

export default DatePicker