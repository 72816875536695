import React from 'react'
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox'

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    plain: true
    branding: true
    campus: true
    kitchen: true
    kiosk: true
    luxo: true
  }
}

export const Checkbox = (props: CheckboxProps) => <MuiCheckbox {...props} />

export default Checkbox
