import { useCallback, useEffect, useMemo, useState } from "react"
import { FormikConfig, FormikHelpers } from "formik"
import { FetchResult, MutationFunction } from "@apollo/client"
import { MutationResult, MutationTuple } from './useMutation'
import { ClientError } from 'graphql/schema/graphql'
import { GraphQLError } from "graphql"

export type OnSubmitFn<MData, MVariables, FValues = MVariables> = (mutationFunction: MutationFunction<MData, MVariables>, values: FValues, formikHelpers: FormikHelpers<FValues>) => Promise<FetchResult<MData>>
export type OnSuccessFn<MData, FValues> = (data: MData, formikHelpers: FormikHelpers<FValues>) => void
export type OnErrorsFn<FValues> = (errors: readonly (ClientError | GraphQLError)[], formikHelpers: FormikHelpers<FValues>) => (ClientError | GraphQLError)[] | false
export type CoerceFn<MVariables, FValues> = (values: FValues) => MVariables

export type MutationFormConfig<MData, MVariables, FValues = MVariables> = Omit<Partial<FormikConfig<FValues>>, 'onSubmit'> & {
  coerce?: CoerceFn<MVariables, FValues>,
  onSubmit?: OnSubmitFn<MData, MVariables, FValues>,
  onSuccess?: OnSuccessFn<MData, FValues>
  onErrors?: OnErrorsFn<FValues>
}

export type MutationFormTuple<MData, FValues> = [
  FormikConfig<FValues>,
  MutationResult<MData> & {
    submitted?: boolean
  },
]

export type UseMutationFn<MData, MVariables> = (variables?: MVariables) => MutationTuple<MData, MVariables>

const defaultCoerce = <MVariables, FValues = MVariables>(values: FValues) => (values as unknown as MVariables)

export const useMutationForm = <MData, MVariables, FValues = MVariables>(
  useMutationFunction: UseMutationFn<MData, MVariables>,
  config?: MutationFormConfig<MData, MVariables, FValues>,
): MutationFormTuple<MData, FValues> => {
  const { initialValues, onSubmit, onSuccess, onErrors } = config
  const coerce = (config.coerce || defaultCoerce<MVariables, FValues>)

  const [ mutationFunction, mutationResult ] = useMutationFunction(coerce(initialValues))

  const [ { formValues, formikHelpers }, setFormik ] = useState<{ formValues?: FValues, formikHelpers?: FormikHelpers<FValues> }>({})

  const [ executed, setExecuted ] = useState<boolean>(false)

  const submitMutationFunction = useCallback(async (values: FValues, formikHelpers: FormikHelpers<FValues>) => {
    setExecuted(false)
    setFormik({ formValues: values, formikHelpers })

    if (onSubmit === undefined) {
      return await mutationFunction({ variables: coerce(values) })
    } else {
      return await onSubmit(mutationFunction, values, formikHelpers)
    }
  }, [ mutationFunction, coerce, onSubmit, setFormik, setExecuted ])

  const submitted = useMemo(() => {
    return mutationResult.called && !mutationResult.loading && mutationResult.data && mutationResult.errors.length === 0
  }, [ mutationResult ])

  const errors = useMemo(() => {
    return mutationResult?.errors.filter(error => !error.path || (formValues && !Object.keys(formValues).includes(error.path[error.path.length - 1])))
  }, [ mutationResult, formValues ])

  useEffect(() => {
    if (executed) {
      return
    } else {
      if (mutationResult.called && !mutationResult.loading) {
        if (mutationResult.errors.length > 0) {
          const mutationErrors = onErrors ? onErrors(mutationResult.errors, formikHelpers) : mutationResult.errors

          if (mutationErrors) {
            mutationErrors.forEach((error: ClientError) => {
              const fieldName = error.path && error.path[error.path.length - 1] as string

              if (fieldName && formValues) {
                if (Object.keys(formValues).includes(fieldName)) {
                  formikHelpers.setFieldError(fieldName, error.message)
                }
              }
            })
          }
        } else if (mutationResult.data) {
          if (onSuccess) onSuccess(mutationResult.data, formikHelpers)
        }
        setExecuted(true)
      }
    }
  }, [ mutationResult, formValues, formikHelpers, onSuccess, onErrors, executed, setExecuted ])

    return [ { onSubmit: submitMutationFunction, validationSchema: config.validationSchema, initialValues }, { ...mutationResult, errors, submitted } ]
  }

export default useMutationForm
