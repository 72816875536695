import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router'
import axios from 'axios'
import apolloClient from 'graphql/client'
import type { LocationWithFromPath } from 'types/routes'
import { useToken } from 'hooks'

const AUTH_SIGN_IN_URL = '/users/sign_in'

export const useLogin = () => {
  const { token, valid, setToken } = useToken()
  const apollo = apolloClient(token)
  const navigate = useNavigate()
  const location: LocationWithFromPath = useLocation()
  const params = new URLSearchParams(location.search)
  const urlToken: string | undefined = params.get('token')
  const urlRefreshToken: string | undefined = params.get('refresh')
  const from = urlToken
    ? "/"
    : (location.state?.from?.pathname
      ? `${location.state?.from?.pathname}${location.state?.from?.search
          ? location.state?.from?.search
          : ''}`
      : "/")

  useEffect(() => {
    if (urlToken && urlRefreshToken) setToken(urlToken, urlRefreshToken)
  }, [urlToken, urlRefreshToken, setToken])

  useEffect(() => {
    if (valid) navigate(from, { replace: true })
  }, [valid, from, navigate])

  // TODO create default axios client for re-use
  const submitLogin = async ({ emailAddress, password }, callbacks) => {
    return axios.post(
      AUTH_SIGN_IN_URL,
      { user: { email: emailAddress, password } },
      { headers: { 'Accept': 'application/json' } }
    )
    .then((response) => {
      setToken(response.headers['Authorization'], response.data.refresh)
      apollo.resetStore()
      if (callbacks?.onSuccess) callbacks.onSuccess(response)
    })
    .catch((err) => {
      if (callbacks?.onError) callbacks.onError(err)
      throw err
    })
  }

  return { token, submitLogin }
}

export default useLogin
