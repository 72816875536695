import type { AppName } from './useBaseUrl'
import { useBaseUrl } from './useBaseUrl'
import { useToken } from './useToken'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useLoginUrl = (appName: AppName): string => {
  const { token, refresh } = useToken()
  const appBaseUrl = useBaseUrl(appName)

  return `${appBaseUrl}/login?token=${token}&refresh=${refresh}`
}

export default useLoginUrl