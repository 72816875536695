import React, { FormEvent } from 'react'
import { Box, Text, Button, TextField } from 'components'
import FormGroup from '@mui/material/FormGroup'

export type RegistrationFormProps = {
  error?: string
  loading?: boolean
  onSubmit?: (e: FormEvent) => void
  onEmailChange?: (email: string) => void
  onFirstNameChange?: (firstName: string) => void
  onLastNameChange?: (lastName: string) => void
  onPasswordChange?: (password: string) => void
  onPasswordConfirmationChange?: (password: string) => void
}

export const RegistrationForm = ({ error, loading, onSubmit, onFirstNameChange, onLastNameChange, onEmailChange, onPasswordChange, onPasswordConfirmationChange }: RegistrationFormProps) => {
  const submitForm = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(e)
  }

  return <Box component="form" onSubmit={submitForm}>
    {error && <Text color="error">{error}</Text>}

    <FormGroup>
      <TextField label="First Name" name="user[first_name]" autoFocus onChange={e => { if (onFirstNameChange) onFirstNameChange(e.target.value) }} />
    </FormGroup>

    <FormGroup>
      <TextField label="Last Name" name="user[last_name]" onChange={e => { if (onLastNameChange) onLastNameChange(e.target.value) }} />
    </FormGroup>

    <FormGroup>
      <TextField label="Email" name="user[email]" onChange={e => { if (onEmailChange) onEmailChange(e.target.value) }} />
    </FormGroup>

    <FormGroup>
      <TextField label="Password" type="password" name="user[password]" onChange={e => { if (onPasswordChange) onPasswordChange(e.target.value) }}/>
    </FormGroup>

    <FormGroup>
      <TextField label="Confirm Password" type="password" name="user[password_confirmation]" onChange={e => { if (onPasswordConfirmationChange) onPasswordConfirmationChange(e.target.value) }}/>
    </FormGroup>

    <FormGroup>
      <Button type="submit" variant="contained" disabled={loading}>{ loading ? "Please wait..." : "Sign up" }</Button>
    </FormGroup>
  </Box>
}

export default RegistrationForm
