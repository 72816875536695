import { useLocalStorage } from './useLocalStorage'
import { useGlobalContext } from './useGlobalContext'
import { useAuthenticated } from './useAuthenticated'
import { useDrawerSettings } from './useDrawerSettings'
import { useRegister } from './useRegister'
import { useLogin } from './useLogin'
import { useLogout } from './useLogout'
import { useToken } from './useToken'
import { useRefreshToken } from './useRefreshToken'
import { useMutation } from './useMutation'
import { useQuery } from './useQuery'
import { useLazyQuery } from './useLazyQuery'
import { useQueryParams } from './useQueryParams'
import { useMe } from './useMe'
import { useViewport } from './useViewport'
import { useFilters } from './useFilters'
import { useForm } from './useForm'
import { useMutationForm } from './useMutationForm'
import { useChangePassword } from './useChangePassword'
import { useChangePasswordForm } from './useChangePasswordForm'
import { useUpdateMe } from './useUpdateMe'
import { useUpdateMeForm } from './useUpdateMeForm'
import { useTodosWizard } from './useTodosWizard'
import { useResolveTodo } from './useResolveTodo'
import { useIgnoreTodo } from './useIgnoreTodo'
import { useHelpDialog } from './useHelpDialog'
import { useBaseUrl } from './useBaseUrl'
import { useLoginUrl } from './useLoginUrl'

export {
  useLocalStorage,
  useGlobalContext,
  useAuthenticated,
  useDrawerSettings,
  useRegister,
  useLogin,
  useLogout,
  useToken,
  useRefreshToken,
  useMutation,
  useQuery,
  useLazyQuery,
  useQueryParams,
  useMe,
  useViewport,
  useFilters,
  useForm,
  useMutationForm,
  useChangePassword,
  useUpdateMe,
  useChangePasswordForm,
  useUpdateMeForm,
  useTodosWizard,
  useResolveTodo,
  useIgnoreTodo,
  useHelpDialog,
  useBaseUrl,
  useLoginUrl,
}

export default {
  useLocalStorage,
  useGlobalContext,
  useAuthenticated,
  useDrawerSettings,
  useRegister,
  useLogin,
  useLogout,
  useToken,
  useRefreshToken,
  useMutation,
  useQuery,
  useLazyQuery,
  useQueryParams,
  useMe,
  useViewport,
  useFilters,
  useForm,
  useMutationForm,
  useChangePassword,
  useUpdateMe,
  useChangePasswordForm,
  useUpdateMeForm,
  useTodosWizard,
  useResolveTodo,
  useIgnoreTodo,
  useHelpDialog,
  useBaseUrl,
  useLoginUrl,
}
