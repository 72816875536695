import React from 'react'
import { Select, SelectProps } from 'components/Select'
import FormField, { FieldProps } from '../FormField'
import { getIn } from 'formik'

type FormSelectProps<Value = unknown> = Partial<FieldProps> & SelectProps<Value>

export const FormSelect: React.FC<FormSelectProps<string | number>> = ({ name, children, helperText, onChange, ...props }) => {
  return (
    <FormField
      name={name}
      component={({ form, field, ...rest }) => {
        const { touched, errors } = form
        return (
          <Select
            {...props}
            {...rest}
            error={Boolean(getIn(touched, name) && getIn(errors, name))}
            helperText={(getIn(touched, name) && getIn(errors, name)) || helperText}
            {...field}
            onChange={(event, child) => {
              if (onChange) onChange(event, child)
              field.onChange(event)
            }}
          >
            {children}
          </Select>
        )
      }}
    />
  )
}

export default FormSelect
