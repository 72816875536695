import React from 'react'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { Check as CheckIcon, Ignore as IgnoreIcon, Incomplete as IncompleteIcon } from 'icons'
import { Box, Button, Chip, Text } from 'components'
import { TodosWizard } from 'hooks/useTodosWizard'
import { TaskWithComponent } from './Todo'
import { useViewport } from 'hooks'

export interface TodoWrapperProps {
  todo: TaskWithComponent
  wizard?: TodosWizard
}

export const TodoWrapper: React.FC<React.PropsWithChildren<TodoWrapperProps>> = ({ todo, wizard, children }) => {
  const { isDesktop } = useViewport()

  if (todo) {
    return <Box display="flex" flexDirection="column" sx={(theme) => ({ maxWidth: theme.spacing(theme.sizes.pageWidth) })}>
      <Box display="flex" justifyContent="space-between">
        <Box sx={{ width: isDesktop ? 'auto' : '100%' }}>
          { isDesktop && <Chip
            sx={{ mb: 2 }}
            label={todo.resolved && todo.status === "incomplete" ? "ready to complete" : todo.status}
            icon={(todo.status === "completed" || todo.resolved) ? (<CheckIcon />) : (todo.status === "ignored" ? <IgnoreIcon /> : <IncompleteIcon />)}
            color={(todo.status === "completed" || todo.resolved) ? "success" : undefined}
            variant={((todo.status !== "completed" && todo.resolved) || todo.status === "ignored") ? "outlined" : undefined}
          /> }
        </Box>

        { isDesktop && <Box>
          { !todo.definition.required && todo.status !== 'ignored' && <Button
            onClick={() => wizard.ignoreActiveTodo({ navigateToNext: true })}
            disabled={todo.resolved}
            color="inherit"
            variant="text"
            size="small"
            fullWidth={false}
          >
            <IgnoreIcon /> <Text pl={1}>Ignore task</Text>
          </Button> }

          { todo.status !== 'completed' && <Button
            onClick={() => wizard.resolveActiveTodo({ navigateToNext: true })}
            disabled={!todo.resolved}
            color="success"
            size="large"
            fullWidth={false}
            sx={{ mr: 2 }}
          >
            <CheckIcon /> <Text pl={1}>Complete task</Text>
          </Button> }
        </Box> }
      </Box>

      <Box>
        <Text variant="h5">
          { todo.name }
        </Text>

        { !isDesktop && <Chip
          sx={{ width: '100%', mt: 1 }}
          label={todo.resolved && todo.status === "incomplete" ? "ready to complete" : todo.status}
          icon={(todo.status === "completed" || todo.resolved) ? (<CheckIcon />) : (todo.status === "ignored" ? <IgnoreIcon /> : <IncompleteIcon />)}
          color={(todo.status === "completed" || todo.resolved) ? "success" : undefined}
          variant={((todo.status !== "completed" && todo.resolved) || todo.status === "ignored") ? "outlined" : undefined}
        /> }
      </Box>

      { todo.description && <Box borderBottom={isDesktop ? undefined : "1px solid #ccc"}>
        <Markdown rehypePlugins={[rehypeRaw]}>{ todo.description }</Markdown>
      </Box> }

      <Box mt={2}>
        { children }
      </Box>
    </Box>
  } else {
    return <Box>TODO: NOT FOUND</Box>
  }
}

export default TodoWrapper
