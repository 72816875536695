import React from 'react'
import { z } from 'zod'
import { Error, Form, FormTextField, FormSubmitButton } from 'components'
import { useForm, useQueryParams } from 'hooks'

const loginSchema = z.object({
  emailAddress: z.string({
    required_error: 'Email address is required',
  })
    .email('Email address is invalid')
    .nonempty(),
  password: z.string({
    required_error: 'Password is required',
  })
    .nonempty()
    .min(6, 'Password must be at least 6 characters'),
})

export type LoginFormInputs = z.infer<typeof loginSchema>;

// TODO Move to shared types
export type SuccessErrorCallbacks = {
  onSuccess?: (res: unknown) => void
  onError?: (err: unknown) => void
}

export type LoginFormProps = {
  onSubmit: (credentials: LoginFormInputs, callbacks: SuccessErrorCallbacks) => Promise<void>
}

export const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const { emailAddress } = useQueryParams({ emailAddress: ''})
  const initState: LoginFormInputs = { emailAddress, password: '' }

  const handleLogin = async (values, cb) => {
    await onSubmit(values, cb)
  }

  const form = useForm({
    initialValues: initState,
    validationSchema: loginSchema,
    onSubmit: handleLogin,
  })
  const { error, isSubmitting } = form

  return (
    <Form form={form}>
      <FormTextField
        id="emailAddress"
        name="emailAddress"
        label="Email address"
        autoFocus={!emailAddress}
        autoComplete="username"
      />
      <FormTextField
        id="password"
        name="password"
        label="Password"
        autoFocus={!!emailAddress}
        autoComplete="current-password"
        type="password"
      />
      {!!error && <Error header="Login Error" content={error} sx={{ mb: 2 }} />}
      <FormSubmitButton
        loading={isSubmitting}
      >
        Log in
      </FormSubmitButton>
    </Form>
  )
}

export default LoginForm
