import React, { PropsWithChildren } from 'react'
import { useDrawerSettings } from 'hooks'
import { Box, Button } from 'components'
import { NavBarProps } from 'compositions/NavBar'
import { NavDrawerProps } from 'compositions/NavDrawer'
import { styled } from '@mui/material'
import { ErrorBoundary } from '@sentry/react'
import { SnackbarProvider, closeSnackbar } from 'notistack'

const Main = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '100%',
  overflowX: 'hidden',
  position: 'relative',
}))

export type LayoutProps = PropsWithChildren<{
  navBar: React.FC<PropsWithChildren<NavBarProps>>
  navDrawer: React.FC<PropsWithChildren<Omit<NavDrawerProps, "AccountMenuItems" | "NavigationMenuItems">>>
}>

export const Layout: React.FC<LayoutProps> = ({ navBar, navDrawer, children }) => {
  const NavBar = navBar
  const NavDrawer = navDrawer
  const [ { navDrawerOpen, accountMenuOpen }, { setNavDrawer, toggleNavDrawer, setAccountMenu } ] = useDrawerSettings()

  return <SnackbarProvider
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    action={(snackbarId) => (
      <Button variant="text" color="inherit" onClick={() => closeSnackbar(snackbarId)}>
        Dismiss
      </Button>
    )}
  >
    <Box height="100%">
      <ErrorBoundary beforeCapture={(scope) => scope.setTag("section", "appBar")}>
        <NavBar toggleDrawer={toggleNavDrawer} />
      </ErrorBoundary>

      <Box sx={{ pt: 8, display: 'flex', height: '100%', width: '100%', overflow: 'auto' }}>
        <ErrorBoundary beforeCapture={(scope) => scope.setTag("section", "navDrawer")}>
          <Box sx={(theme) => ({ width: navDrawerOpen ? theme.spacing(theme.sizes.drawerWidth) : 0 })}>
            <NavDrawer
              anchor="left"
              open={navDrawerOpen}
              toggleDrawer={setNavDrawer}
              accountMenuOpen={accountMenuOpen}
              setAccountMenuOpen={setAccountMenu}
            />
          </Box>
        </ErrorBoundary>

        <ErrorBoundary beforeCapture={(scope) => scope.setTag("section", "main")}>
          <Main component="main">
            { children }
          </Main>
        </ErrorBoundary>

        <ErrorBoundary beforeCapture={(scope) => scope.setTag("section", "contentDrawer")}>
          <Box id="ContentDrawerPortal" height="100%" />
        </ErrorBoundary>
      </Box>
    </Box>
  </SnackbarProvider>
}

export default Layout