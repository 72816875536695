import { resolveTodoMutation } from 'graphql/mutations'
import { MutationTuple, useMutation } from './useMutation'
import type { ResolveTodoInput, ResolveTodoPayload } from 'graphql/schema/graphql'
export type { ResolveTodoInput }

export type ResolveTodoMutationData = { resolveTodo: ResolveTodoPayload }

export const useResolveTodo: (variables?: ResolveTodoInput) => MutationTuple<ResolveTodoMutationData, ResolveTodoInput> = (variables)  => {
  const [ resolveTodoFn, resolveTodoResult ] = useMutation<ResolveTodoMutationData, ResolveTodoInput>(
    resolveTodoMutation,
    {
      variables,
      notifyOnNetworkStatusChange: true
    }
  )

  return [ resolveTodoFn, resolveTodoResult ]
}

export default useResolveTodo
