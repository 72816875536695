import React, { PropsWithChildren } from 'react'
import Message, { MessageTitle, MessageProps } from '../Message'

export type ErrorProps = MessageProps & {
  content?: React.ReactNode;
  header?: string;
};

const Error: React.FC<PropsWithChildren<ErrorProps>> = ({
  content, header, children, ...rest
}) => {
  return (
    <Message severity="error" {...rest}>
      {header && <MessageTitle>{header}</MessageTitle>}
      {content ?? children}
    </Message>
  )
}

export { Error }

export default Error
