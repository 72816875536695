import React from 'react'
import { TextFieldProps } from '@mui/material/TextField'
import PlainTextField from './PlainTextField'
import PasswordField from './PasswordField'
import TelephoneField from './TelephoneField'

export type { TextFieldProps }

export const TextField = (props: TextFieldProps) => {
  const { type } = props
  if (type === 'password') return <PasswordField {...props} />
  if (type === 'tel') return <TelephoneField {...props} />
  return <PlainTextField {...props} />
}

TextField.defaultProps = {
  fullWidth: true,
}

export default TextField
