import React, { PropsWithChildren } from 'react'
import { IntlProvider } from 'react-intl'
import { Provider as ReduxProvider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import { LicenseInfo } from '@mui/x-license-pro'
import { ThemeProvider, Theme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { LuxonAdapter } from 'utils/LuxonAdapter'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { BrowserRouter } from 'react-router-dom'
import { ContextProvider } from './Context'
import client, { ClientNames } from 'graphql/client'
import { useRefreshToken } from 'hooks'
import { store } from './store'
import '@fontsource/outfit/300.css'
import '@fontsource/outfit/400.css'
import '@fontsource/outfit/500.css'
import '@fontsource/outfit/700.css'
import '@fontsource/material-icons/index.css'

LicenseInfo.setLicenseKey(process.env.MUI_X_LICENSE_KEY)

const ApolloWrapper: React.FC<PropsWithChildren<{ clientName?: ClientNames }>> = ({ children, clientName }) => {
  const { token } = useRefreshToken()

  return <ApolloProvider client={client(token, clientName)}>
    { children }
  </ApolloProvider>
}

export const Wrapper: React.FC<PropsWithChildren<{ theme?: Theme, apolloClient?: ClientNames }>> = ({ children, theme, apolloClient }) => {
  const metaBasename: HTMLMetaElement = document.head.querySelector('meta[name="basename"]')
  const basename: string | undefined = metaBasename ? metaBasename.content : undefined

  return <ReduxProvider store={store}>
    <ContextProvider>
      <ApolloWrapper clientName={apolloClient}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <IntlProvider locale="en-us">
            <LocalizationProvider dateAdapter={LuxonAdapter}>
              <BrowserRouter basename={basename}>
                { children }
              </BrowserRouter>
            </LocalizationProvider>
          </IntlProvider>
        </ThemeProvider>
      </ApolloWrapper>
    </ContextProvider>
  </ReduxProvider>
}

export default Wrapper
