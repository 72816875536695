import React, { PropsWithChildren, ReactElement } from 'react'
import styled from '@mui/material/styles/styled'
import {
  Formik,
  Form as FormikForm,
  FormikConfig,
  FormikValues,
} from 'formik'

interface FormProps<T extends FormikValues = object> {
  children: ReactElement | ReactElement[];
  form: FormikConfig<T>;
}

const FormStyled = styled(FormikForm)(({ theme }) => ({
    maxWidth: theme.spacing(theme.sizes.formWidth),
    marginLeft: (typeof theme.sizes.formMargins === 'number') ? theme.spacing(theme.sizes.formMargins) : theme.sizes.formMargins,
    marginRight: (typeof theme.sizes.formMargins === 'number') ? theme.spacing(theme.sizes.formMargins) : theme.sizes.formMargins,
    marginTop: '1rem',
    marginBottom: '1rem',
    '& .MuiFormControl-root': {
      marginBottom: '1rem',
    },
    '& .MuiButton-root': {
      marginBottom: '1rem',
    }
}))

export const Form: React.FC<PropsWithChildren<FormProps>> = ({ children, form: { validateOnBlur=false, validateOnChange=false, ...form } }) => {
  return (
    <Formik {...form} validateOnBlur={validateOnBlur} validateOnChange={validateOnChange}>
      <FormStyled>
        {children}
      </FormStyled>
    </Formik>
  )
}

export default Form
