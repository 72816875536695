import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'compositions/Application/store'

export interface DrawerSettingsState {
  navDrawerOpen: boolean
  accountMenuOpen: boolean
  contentDrawerOpen: boolean
}

const initialState: DrawerSettingsState = {
  navDrawerOpen: false,
  accountMenuOpen: true,
  contentDrawerOpen: false,
}

export const drawerSettingsSlice = createSlice({
  name: 'drawerSettings',
  initialState,
  reducers: {
    setNavDrawer: (state, action: PayloadAction<boolean>) => {
      state.navDrawerOpen = action.payload
    },
    toggleNavDrawer: (state) => {
      state.navDrawerOpen = !state.navDrawerOpen
    },

    setAccountMenu: (state, action: PayloadAction<boolean>) => {
      state.accountMenuOpen = action.payload
    },
    toggleAccountMenu: (state) => {
      state.accountMenuOpen = !state.accountMenuOpen
    },

    setContentDrawer: (state, action: PayloadAction<boolean>) => {
      state.contentDrawerOpen = action.payload
    },
    toggleContentDrawer: (state) => {
      state.contentDrawerOpen = !state.contentDrawerOpen
    },
  },
})

export const {
  setNavDrawer,
  toggleNavDrawer,
  setAccountMenu,
  toggleAccountMenu,
  setContentDrawer,
  toggleContentDrawer,
} = drawerSettingsSlice.actions

export const drawerSettingsSelector = (state: RootState) => state.drawerSettings

export default drawerSettingsSlice.reducer