import React, { useCallback, useMemo } from 'react'
import { Box, Chip } from 'components'
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select'
import SelectOption from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

export type SelectOptionObject<Value = unknown> = { value: Value, label?: string }
export type SelectProps<Value = unknown> = MuiSelectProps<Value> & {
  options?: (Value | SelectOptionObject<Value> | [Value, string?])[]
  labelId?: string
  fullWidth?: boolean
  helperText?: string
  error?: boolean
}

export { SelectOption }

export const Select: React.FC<SelectProps<string | number>> = ({ options, children, helperText, error, multiple, fullWidth=true, ...props }) => {
  const labelId = props.labelId || `${props.id || props.name}-label`

  const mappedOptions = useMemo(() => {
    if (!options || !Array.isArray(options)) return []

    return options.map(option => {
      if (Array.isArray(option)) {
        if (option.length > 1) {
          return { value: option[0], label: option[1] }
        } else {
          return { value: option[0], label: option[0] }
        }
      } else if (typeof option === 'object') {
        return option
      } else {
        return { value: option, label: option }
      }
    })
  }, [ options ])

  const renderValues = useCallback((selected: string | number | (string | number)[]) => {
    const selectedOptions = Array.isArray(selected) && selected.map((value) => {
      if (mappedOptions && mappedOptions.length > 0) {
          return mappedOptions.find(opt => opt.value === value)
      } else {
        return { value, label: value }
      }
    })

    return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      { selectedOptions && selectedOptions.sort((a, b) => a.label.toLocaleString().localeCompare(b.label.toLocaleString())).map((option) => {
          return <Chip key={option.value} label={option.label} />
      })}
    </Box>
  }, [ mappedOptions ])

  return <FormControl fullWidth={fullWidth} error={error}>
    <InputLabel id={labelId} sx={{ pl: 1.5 }}>{props.label}</InputLabel>

    <MuiSelect
      {...props}
      multiple={multiple}
      labelId={labelId}
      renderValue={(multiple && options) ? renderValues : undefined}
    >
      { children || mappedOptions.map(option => <SelectOption key={option.value} value={option.value}>{option.label || option.value}</SelectOption>) }
    </MuiSelect>

    { helperText && <FormHelperText>{ helperText }</FormHelperText> }
  </FormControl>
}

export default Select
