import React from 'react'
import { Box, BoxProps } from '../Box'
import styled from '@mui/material/styles/styled'

export type PageProps = BoxProps & { fullWidth?: boolean, autoHeight?: boolean }

export const PageBox = styled(Box)<PageProps>(({ theme, fullWidth, autoHeight }) => ({
  ...(!autoHeight && {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }),
  ...(!fullWidth && {
    maxWidth: theme.spacing(theme.sizes.pageWidth),
    marginLeft: (typeof theme.sizes.pageMargins === 'number') ? theme.spacing(theme.sizes.pageMargins) : theme.sizes.pageMargins,
    marginRight: (typeof theme.sizes.pageMargins === 'number') ? theme.spacing(theme.sizes.pageMargins) : theme.sizes.pageMargins,
  }),
}))

export const Page: React.FC<React.PropsWithChildren<PageProps>> = (props) => {
    return <PageBox {...props} />
}

export default Page
